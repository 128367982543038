import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERPAuthService, Memoise } from '@erp/shared';
import { DashboardControlArgs } from 'devexpress-dashboard/common';
import { ERPApiConfigService, PageTitleService } from '@erp/core';

@Component({
  selector: 'erp-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.scss']
})
export class ERPReportComponent implements OnInit {
  headers: { Authorization: string };
  id: string;
  name: string;

  constructor(
    private readonly authService: ERPAuthService,
    readonly titleService: PageTitleService,
    private route: ActivatedRoute,
    private router: Router,
    public readonly $apiConfigService: ERPApiConfigService
  ) {}

  ngOnInit() {
    const token = this.authService.getCookie('JWT-Cookie');
    this.headers = { Authorization: `bearer ${token}` };

    this.getRouteData();
    this.setTittle();
  }

  getRouteData() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    this.route.queryParams.subscribe(queryParams => {
      this.name = queryParams['name'];
    });
  }
  setTittle() {
    this.router.events.subscribe(() => {
      this.titleService.setTitle(this.name);
    });
  }

  @Memoise()
  get endpoint(): string {
    const endpoint = `${this.$apiConfigService.domain}/gateway/api/dashboard`;

    return endpoint;
  }

  onDashboardInitialized(args: DashboardControlArgs) {
    const dashboardControl = args.component;
    const state = dashboardControl.getDashboardState();
    const reverseHour = -60;

    const updatedState = JSON.stringify({
      Parameters: {
        ...JSON.parse(state).Parameters,
        TimeZoneOffset: '' + new Date().getTimezoneOffset() / reverseHour
      }
    });

    dashboardControl.setDashboardState(updatedState);
  }
}
