import { BehaviorSubject, Observable, fromEvent, identity, of, race } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  pairwise,
  share,
  startWith,
  takeUntil,
  tap
} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';
import {
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger
} from '@angular/material/legacy-autocomplete';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';

import { AutoCleanupFeature, BaseControlComponent, ERPFormStateDispatcher, Features } from '@erp/shared';

import { ISelectOption } from '../select';

const VALUE_CHANGE_DEBOUNCE_TIME = 500;
const SCROLL_DEBOUNCE_TIME = 300;
const NO_ITEMS = 0;
const SINGLE_ITEM = 1;
const DEFAULT_MINLENGTH = 1;
const SCROLL_THRESHOLD = 80;

@Component({
  selector: 'erp-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: []
})
@Features([AutoCleanupFeature()])
export class ERPAutocompleteComponent<T> extends BaseControlComponent<T> implements OnInit {
  readonly destroyed$: Observable<unknown>;
  @Input() valueFn: (value: T | null) => T | null = identity;
  @Input() readonly labelFn = identity;
  @Input() readonly displayFn = identity;
  @Input() readonly optionsFn: (search: string | null, length?: number) => Observable<T[]>;
  @Input() readonly placeholder: string | null;
  @Input() readonly readonly: boolean;
  @Input() readonly icon: string | null;
  @Input() readonly clearable: boolean = true;
  @Input() readonly multiselect: boolean = false;
  @Input() readonly autoSelect: boolean = false;
  @Input() readonly openOnFocus = false;
  @Input() readonly createNew = false;
  @Input() readonly labelProp: string | null;
  @Input() readonly isFocused: boolean;
  @Input() set disabled(value: boolean) {
    if (value) {
      this.control.disable();
    }
  }
  @Input() readonly populateValueByID: boolean = false;

  // form builder specific props
  @Input() readonly isFormBuilder: boolean = false;
  @Input() readonly optionsPaging: boolean = false;

  @Output() readonly changed = new EventEmitter<T | null>();
  @Output() readonly multiSelected = new EventEmitter<T | null>();

  @ViewChild(MatAutocompleteTrigger, {
    static: true
  })
  readonly autocomplete: MatAutocompleteTrigger;
  @ViewChild(MatInput, {
    read: ElementRef,
    static: true
  })
  readonly input: ElementRef<HTMLInputElement>;

  readonly control = new UntypedFormControl(null);
  readonly loading$ = new BehaviorSubject<boolean>(false);

  @Input() readonly minlength = DEFAULT_MINLENGTH;
  @Input() readonly makeInitialRequest = false;

  options$: Observable<T[] | null>;
  options: T[] = [];

  readonly trackByFn: TrackByFunction<T> = (_index: number, element: T) => element;

  readonly modelToViewFormatter = (value: T | null) => {
    if (value === null) {
      this.emptyOptions();
    }

    return value;
  };

  readonly viewToModelParser = (value: T | null) => {
    return value ? this.valueFn(value) : null;
  };

  constructor(
    readonly element: ElementRef<HTMLElement>,
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: ERPFormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  get active() {
    return this.input?.nativeElement === document.activeElement;
  }

  get length() {
    return this.input?.nativeElement.value.length;
  }

  get open() {
    return this.autocomplete.panelOpen;
  }

  get loadOnScroll() {
    if (this.isFormBuilder) {
      return this.optionsPaging;
    } else {
      return this.optionsFn.length > 1;
    }
  }

  get canAddNew() {
    return (
      this.createNew &&
      this.control.value?.length &&
      this.options.findIndex(opt => {
        const value = this.labelFn(opt);
        if (typeof value === 'string') {
          return value.toLowerCase() === this.control.value.toLowerCase();
        }

        return value === this.control.value;
      }) === -1
    );
  }

  getNewOption(value: string) {
    if (this.labelProp) {
      return { [this.labelProp]: value };
    } else {
      return { value };
    }
  }

  @HostListener('focus')
  focusHandler() {
    if (this.openOnFocus) {
      this.onSelectValue();
    }
  }

  ngOnInit() {
    if (!this.valueFn) {
      this.valueFn = this.autoSelect ? (value: T | null) => (value instanceof Object ? value : null) : identity;
    }

    const validator = this.ctrl.control?.validator ?? null;
    const asyncValidator = this.ctrl.control?.asyncValidator ?? null;

    this.control.setValidators(validator);
    this.control.setAsyncValidators(asyncValidator);
    this.onValidatorChange?.();

    this.formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.control.markAsTouched();
      this.changeDetector.markForCheck();
    });

    this.control.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(VALUE_CHANGE_DEBOUNCE_TIME), takeUntil(this.destroyed$))
      .subscribe(() => {
        const { value } = this.input.nativeElement;
        const trimmedValue = value?.trim();

        this.options$ = (this.length < this.minlength ? of([]) : this.loadOptions(trimmedValue, 0)).pipe(
          tap(options => {
            this.options = options;
          })
        );
        this.changeDetector.markForCheck();
      });

    this.ctrl.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const errors = this.ctrl.control?.errors ?? null;

      this.control.setErrors(errors);
      this.changeDetector.markForCheck();
    });

    if (this.makeInitialRequest) {
      this.control.updateValueAndValidity();
    }
  }

  writeValue(value: T | null): void {
    if (this.populateValueByID) {
      this.onPopulateByID(value);
    } else {
      this.control.setValue(this.modelToViewFormatter(value), { emitEvent: false });
    }
  }

  onPopulateByID(value: T | null): void {
    this.loadOptions('')
      .pipe(
        takeUntil(this.destroyed$),
        map(res => res as unknown as ISelectOption[])
      )
      .subscribe(res => {
        const valueForPatch = res.find(item => item.id === (value as unknown as number)) ?? null;

        this.control.setValue(valueForPatch, { emitEvent: false });
      });
  }
  onInput() {
    const { value } = this.autoSelect ? this.input.nativeElement : this.control;

    if (this.autoSelect && (this.ctrl.control?.validator || value?.length)) {
      this.ctrl.control?.setErrors({
        nomatch: null
      });
    }
  }

  onEnterKeyDown(event: KeyboardEvent) {
    event.preventDefault();
  }

  onBackspaceKeyUp(event: KeyboardEvent) {
    // emit if cleared (KO)
    if (!this.value) {
      this.changed.emit(null);
    }
  }

  onFocus() {
    this.onTouched?.();
  }

  onSelectOption(event: MatAutocompleteSelectedEvent) {
    const value = this.valueFn(event.option.value);

    this.closeAutocomplete();
    this.ctrl.control?.markAsTouched();
    this.control.markAsTouched();
    this.changed.emit(value);
    this.control.setValue(this.control.value);
  }

  onSelectValue() {
    const { value } = this.autoSelect ? this.input.nativeElement : this.control;
    if (this.control.disabled) {
      return;
    }

    this.emptyOptions();

    if (this.autoSelect) {
      return this.queryAndAutoSelect(value);
    }

    this.changed.emit(value);

    this.closeAutocomplete();
  }

  onClearValue() {
    if (this.readonly || this.control.disabled) {
      return;
    }

    this.ctrl.control?.markAsTouched();
    this.control.setValue(null);

    this.closeAutocomplete();
    this.emptyOptions();
    this.changed.emit(null);
    this.changeDetector.markForCheck();
  }

  onSelectMulti() {
    const { value } = this.autoSelect ? this.input.nativeElement : this.control;
    if (this.control.disabled) {
      return;
    }

    this.emptyOptions();

    this.multiSelected.emit(value);
    this.closeAutocomplete();
  }

  onAutocompleteOpen() {
    this.setAutocompleteMinWidth();
    this.setAutocompleteScrollBehaviour();
  }

  protected setAutocompleteScrollBehaviour() {
    const { autocomplete } = this.autocomplete;
    const element = autocomplete.panel?.nativeElement as HTMLElement;

    if (!this.loadOnScroll || !element) {
      return;
    }

    fromEvent(element, 'scroll', { passive: true })
      .pipe(
        startWith(element.scrollHeight - element.offsetHeight),
        distinctUntilChanged(),
        debounceTime(SCROLL_DEBOUNCE_TIME),
        map(() => element.scrollHeight - (element.scrollTop + element.offsetHeight)),
        pairwise(),
        filter(([prevThreshold, currThreshold]) => currThreshold <= prevThreshold),
        filter(([, currThreshold]) => currThreshold <= SCROLL_THRESHOLD),
        takeUntil(race([autocomplete.closed, autocomplete.opened, this.control.valueChanges, this.destroyed$]))
      )
      .subscribe(() => {
        const { value } = this.input.nativeElement;

        this.options$ = this.loadOptions(value, this.options?.length).pipe(
          map(options => {
            this.options = [...(this.options ?? []), ...options];

            return this.options;
          }),
          startWith(this.options)
        );
        this.changeDetector.markForCheck();
      });
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);

    if (this.makeInitialRequest) {
      this.control.updateValueAndValidity();
    }

    this.changeDetector.markForCheck();
  }

  protected loadOptions(value: string | null, length?: number) {
    this.loading$.next(true);

    this.changeDetector.markForCheck();

    return this.optionsFn(value, length).pipe(
      share(),
      tap(() => this.loading$.next(false)),
      catchError(() => {
        this.loading$.next(false);

        return of([]);
      })
    );
  }

  protected setAutocompleteMinWidth() {
    const { panel } = this.autocomplete.autocomplete;
    const panelElement = panel?.nativeElement as HTMLElement;
    const elementRect = this.element.nativeElement.getBoundingClientRect();
    const minWidth = elementRect.width;

    if (panelElement) {
      panelElement.style.minWidth = CSS.px(minWidth).toString();
      this.autocomplete.updatePosition();
    }
  }

  protected queryAndAutoSelect(value: string) {
    const { autocomplete } = this.autocomplete;

    this.options$ = this.loadOptions(value, this.options?.length).pipe(
      tap(options => {
        this.options = options;
      })
    );

    this.options$
      .pipe(first(), takeUntil(race([autocomplete.closed, this.control.valueChanges, this.destroyed$])))
      .subscribe(options => {
        this.changeDetector.markForCheck();

        switch (options?.length) {
          case NO_ITEMS:
            if (value?.length) {
              this.ctrl.control?.setErrors({
                nomatch: true
              });
            }

            return this.closeAutocomplete();
          case SINGLE_ITEM:
            this.control.setValue(options[0]);
            this.changed.emit(options[0]);

            return this.closeAutocomplete();
          default:
            return this.openAutocomplete();
        }
      });
  }

  protected emptyOptions() {
    this.options$ = of(null);
    this.options = [];
  }

  protected openAutocomplete() {
    this.autocomplete.openPanel();
    this.input.nativeElement.focus();
  }

  protected closeAutocomplete() {
    setTimeout(() => {
      this.autocomplete.closePanel();
      this.input.nativeElement.blur();
      if (this.multiselect) {
        setTimeout(() => {
          this.input.nativeElement.focus();
        }, VALUE_CHANGE_DEBOUNCE_TIME);
      }
    });
  }
}
