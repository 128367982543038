import { APP_INITIALIZER, ApplicationRef, ComponentRef, DoBootstrap, NgModule } from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '@environment';
import {
  ERPButtonModule,
  ERPIconModule,
  ERPInputModule,
  ERPNotificationEventsService,
  ERPNotificationListenerService,
  ERPNotificationsModule,
  ERPRedirectModule,
  ERPRoutingSpinnerModule,
  ERPSelectModule,
  ERPSidenavModule,
  ERPToasterModule
} from '@erp/components';
import { ERPApiConfigInitializer, ERPCoreModule } from '@erp/core';
import {
  ERPRouterExtService,
  ERPSharedModule,
  ERPSignalRConnectionService,
  ERP_ENV_API_URL,
  ERP_ENV_API_DOMAIN,
  ERPSettingsService
} from '@erp/shared';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ERPAppRoutingModule } from './app-module.routing';
import { ERPAppComponent, ERPFooterComponent, ERPLandingComponent } from './components';
import { MatStepperModule } from '@angular/material/stepper';
import { ERPApiConfigService } from '../../../../libs/core/src/lib/services/api-config.service';

@NgModule({
  declarations: [ERPAppComponent, ERPLandingComponent, ERPFooterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register(environment.serviceWorkerPath, {
      enabled: environment.production
    }),
    MatCardModule,
    MatDialogModule,
    ERPCoreModule,
    ERPSharedModule,
    ERPIconModule,
    ERPSelectModule,
    ERPButtonModule,
    ERPToasterModule,
    ERPInputModule,
    ERPRoutingSpinnerModule,
    ERPRedirectModule,
    ERPAppRoutingModule,
    ERPSidenavModule,
    ERPNotificationsModule,
    MatProgressSpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatStepperModule
  ],
  providers: [
    ERPSignalRConnectionService,
    ERPNotificationListenerService,
    ERPNotificationEventsService,
    ERPRouterExtService,
    CookieService,
    provideNgxMask(),
    {
      provide: ERP_ENV_API_DOMAIN,
      useValue: environment.apiDomain
    },
    {
      provide: ERP_ENV_API_URL,
      useValue: environment.apiUrl
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ERPApiConfigInitializer,
      multi: true,
      deps: [ERPApiConfigService, ERP_ENV_API_DOMAIN, ERP_ENV_API_URL]
    }
  ]
})
export class ERPAppModule implements DoBootstrap {
  root: ComponentRef<ERPAppComponent>;

  ngDoBootstrap(appRef: ApplicationRef) {
    this.root = appRef.bootstrap(ERPAppComponent);
  }
}
