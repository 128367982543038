import { Observable, identity } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AbstractControl, NgControl, UntypedFormControl, ValidationErrors, Validator } from '@angular/forms';

import { IDestroyable } from '../../lifecycle';
import { BaseControl } from '../base';

export abstract class BaseControlComponent<T, R = T> extends BaseControl<T> implements Validator, IDestroyable {
  readonly destroyed$: Observable<unknown>;
  abstract readonly control: UntypedFormControl;
  protected onValidatorChange?: () => void;
  protected onChanged?: (value?: T | R | null) => void;
  protected onTouched?: () => void;

  readonly ctrl: NgControl;

  protected readonly modelToViewFormatter: (value: T | null) => T | R | null = identity;
  protected readonly viewToModelParser: (value: R | null) => T | R | null = identity;

  constructor() {
    super();
    setTimeout(() => {
      if (this.ctrl?.control) {
        const func = this.ctrl.control.markAsUntouched;

        this.ctrl.control.markAsUntouched = (opts?: { onlySelf?: boolean }) => {
          func?.call(this.ctrl.control, opts);
          this.control.markAsUntouched(opts);
        };
      }
    });
  }

  get value(): T {
    return this.control.value;
  }

  writeValue(value: T | null): void {
    this.control.setValue(this.modelToViewFormatter(value), { emitEvent: false });
  }

  registerOnChange(fn: (value: T) => void) {
    super.registerOnChange(fn);

    this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.onTouched?.();
      this.onChanged?.(this.viewToModelParser(value));
    });
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return control.valid ? null : control.errors;
  }
}
