import { Injectable } from '@angular/core';

import { ISalesOrder, ISalesOrderInfo, ISalesOrderResponse } from '../interfaces/sales-order.interface';

@Injectable({
  providedIn: 'root'
})
export class ERPSharedSalesOrderFactory {
  toRequest(order: ISalesOrder): ISalesOrderResponse {
    return {
      ...order.salesOrderInfo,
      id: order.id,
      rowVersion: order.rowVersion,
      address: order.shippingAddressInfo,
      lines: order.lines,
      shipVia: order.termsAndConditions.shipVia ?? null,
      freight: order.termsAndConditions.freight ?? null,
      terms: order.termsAndConditions.terms ?? null,
      class: order.termsAndConditions.class ?? null,
      fob: order.termsAndConditions.fob ?? null,
      tagNumber: order.termsAndConditions.tagNumber ?? null,
      attention: order.termsAndConditions.attention ?? null,
      revision: order.salesOrderInfo.revision ?? 1,
      firstSalesCommision: order.salesOrderInfo.firstSalesCommision ?? 0,
      secondSalesCommision: order.salesOrderInfo.secondSalesCommision ?? 0,
      // billingDetails
      isPrePaid: order.billingDetails?.prePaid ?? false,
      isNonCancelled: order.billingDetails?.nonCancelled ?? false,
      isNonReturnable: order.billingDetails?.nonReturnable ?? false,
      prePaidAmount: order.billingDetails?.prePaidAmount ?? null,
      billingAddress: order.billingAddress ?? null
    };
  }

  fromResponse(orderResponse: ISalesOrderResponse): ISalesOrder {
    const { id, address, rowVersion, lines, ...order } = orderResponse;

    const result = {
      id,
      rowVersion,
      salesOrderInfo: order as unknown as ISalesOrderInfo,
      shippingAddressInfo: address,
      lines,
      termsAndConditions: {
        shipVia: orderResponse.shipVia ?? null,
        freight: orderResponse.freight ?? null,
        terms: orderResponse.terms ?? null,
        class: orderResponse.class ?? null,
        fob: orderResponse.fob ?? null,
        tagNumber: orderResponse.tagNumber ?? null,
        attention: orderResponse.attention ?? null
      },
      billingDetails: {
        prePaid: orderResponse.isPrePaid ?? false,
        prePaidAmount: orderResponse.prePaidAmount,
        nonCancelled: orderResponse.isNonCancelled ?? false,
        nonReturnable: orderResponse.isNonReturnable ?? false
      },
      billingAddress: orderResponse.billingAddress
    };

    return result;
  }
}
