import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse, IMatchedCustomer, IMetadataFiltering } from '@erp/shared';

import { ICustomer, ICustomerListResponse, ICustomerQuery, ICustomerResponse, ITransactionList } from '../models';

import { ERPCustomerFactory } from './customer.factory';

@Injectable({
  providedIn: 'root'
})
export class ERPCustomerService extends BaseHttpService {
  constructor(
    readonly customerFactory: ERPCustomerFactory,
    readonly httpClient: HttpClient
  ) {
    super('customers');
  }

  getCustomers(params: object) {
    return this.get<ICollectionResponse<ICustomerListResponse>>(params);
  }

  getCustomerById(id: number) {
    return this.get<ICustomerResponse>(undefined, id);
  }

  getMatchedCustomers(params: ICustomerQuery) {
    return this.get<IMatchedCustomer[]>(params, 'reference');
  }

  getReferenceNumbers(searchString: string): Observable<string[]> {
    return this.get<string[]>({ searchString }, 'referenceNumbers').pipe(
      map(numbers =>
        numbers.sort((a, b) =>
          a.toLowerCase() > b.toLocaleLowerCase() ? 1 : b.toLocaleUpperCase() > a.toLocaleLowerCase() ? -1 : 0
        )
      )
    );
  }

  getMatchedCustomersByStatus(params: ICustomerQuery): Observable<IMatchedCustomer[]> {
    const metadata = { filtering: [] as IMetadataFiltering[], sort: [{ by: 'customerName', direction: 'desc' }] };

    if (params.customerName) {
      metadata.filtering.push({
        by: 'customerName',
        match1: params.customerName,
        op: 'contains'
      } as IMetadataFiltering);
    }
    if (params.statusId) {
      metadata.filtering.push({
        by: 'statusId',
        match1: params.statusId,
        op: 'eq'
      } as IMetadataFiltering);
    }

    return this.get<ICollectionResponse<IMatchedCustomer>>({ query: JSON.stringify(metadata) }).pipe(
      map(({ data }) => data)
    );
  }

  getCustomer(id: string) {
    return this.get<ICustomerResponse>({}, id).pipe(map(response => this.customerFactory.fromResponse(response)));
  }

  createCustomer(customer: ICustomer) {
    const request = this.customerFactory.toRequest(customer);

    return this.post<ICustomerResponse>(request);
  }

  saveCustomer(customer: ICustomer, id: string) {
    const request = this.customerFactory.toRequest(customer);

    return this.put<ICustomerResponse>(request, {}, id);
  }

  deleteCustomer(id: string) {
    return this.delete({}, id);
  }

  uploadAttachment(file: FormData, customerId: string) {
    return this.post<FormData>(file, {}, `${customerId}/attachment`, { reportProgress: true, observe: 'events' });
  }

  downloadAttachment(attachmentId: string, customerId: string): Observable<Blob> {
    return this.get({}, `${customerId}/attachment/${attachmentId}`, { responseType: 'blob' });
  }

  deleteAttachment(attachmentId: string, customerId: string) {
    return this.delete({}, `${customerId}/attachment/${attachmentId}`);
  }

  getCustomerDocuments(params: object, id: string) {
    return this.get<ICollectionResponse<ITransactionList>>(params, `${id}/documents`);
  }
}
