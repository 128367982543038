import {
  AfterViewInit,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';

import { ACTION_COLUMN_NAMES, ERPPageIdentifierService } from '@erp/shared';

import { ERPTableOrderComponent } from '../components';
import { BehaviorSubject, Subject } from 'rxjs';

export const ACTION_TITLES = {
  action: 'Action',
  actions: 'Actions',
  isSelected: 'Selected',
  selected: 'Selected',
  delete: 'Delete',
  'delete-action': 'Delete',
  'save-action': 'Save',
  change: 'Update',
  close: 'Close',
  save: 'Save',
  confirmStatusId: 'Confirm status'
};

type Key<T> = keyof T | string;
type ColsResponse = { order: string[]; active: string[] };

@Directive({
  selector: '[erpTableOrder]'
})
export class ERPTableOrderDirective<T> implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  private _inactiveColumns: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  @Output() readonly erpOrderColumnsChange = new EventEmitter<Key<T>[]>();
  @Input() erpOrderColumns: Key<T>[];

  @Input() set erpHiddenColumns(cols: string[]) {
    this.hiddenCols = cols ?? [];

    if (this.setMinWidth) {
      this.onSetMinWidth();
    }

    if (this.componentRef) {
      setTimeout(() => {
        this.componentRef.instance.hiddenColumns = cols;
        this.componentRef.instance.columns = [...this.erpOrderColumns] as string[];
        this.initColumnsData();
        this.changeDetector.markForCheck();
      });
    }
  }

  @Input() set inactiveColumns(cols: string[]) {
    this._inactiveColumns.next(cols);
  }

  @Input() disabledColumns: Key<T>[] = [];
  @Input() requiredColumns: Key<T>[] = [];
  @Input() storageIdentifier: string;
  @Input() setMinWidth: boolean;
  @Input() iconOnHeader = false;

  componentRef: ComponentRef<ERPTableOrderComponent>;
  columnsOrder: string[];
  hiddenCols: string[] = [];
  tableRef: HTMLTableElement;
  observer: MutationObserver;
  displayedColumnNames = new Map<string, { name: string; disabled: boolean }>();
  disabledColPosition: { [key: string]: number } = {};
  originalColumns: Key<T>[];
  private actionColumnNames = ACTION_COLUMN_NAMES;

  constructor(
    private readonly el: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    private pageIdentifierService: ERPPageIdentifierService,
    readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(ERPTableOrderComponent);
    this.componentRef = this.viewContainerRef.createComponent(componentFactory);

    this.onSetMinWidth();

    if (this.iconOnHeader) {
      this.componentRef.instance.icon = 'action:order-white';
    }

    this.componentRef.instance.columns = [...this.erpOrderColumns] as string[];

    this.componentRef.instance.orderChanged.subscribe((res: ColsResponse) => {
      if (res) {
        let parsedCols = this.setDisabledColumns(res);
        this.saveOrder(parsedCols);

        parsedCols = parsedCols.filter(
          i =>
            res.active.includes(i as string) ||
            (Object.keys(this.disabledColPosition).includes(i as string) && !this.hiddenCols.includes(i as string))
        );
        this.saveActive(parsedCols);

        this.erpOrderColumnsChange.emit(parsedCols);
        this.onSetMinWidth(parsedCols.length);
        this.componentRef.instance.columnsOrder = res.order as string[];
        this.componentRef.instance.activeColumns = res.active as string[];
        this.handleFooterColumns(parsedCols as string[]);
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const activeElement = this.el.nativeElement;
      this.tableRef = activeElement.closest('table') || activeElement;
      this.setPosition();
      this.initColumnsData();
      this.handleFooterColumns();
      this.observePositionChange();

      this._inactiveColumns.subscribe(cols => {
        if (this.componentRef && cols?.length) {
          setTimeout(() => {
            const isUserCustomisation = localStorage.getItem(this.orderTableKey);

            if (!isUserCustomisation) {
              const res: ColsResponse = {
                order: this.erpOrderColumns as string[],
                active: (this.erpOrderColumns as string[]).filter(i => !cols.includes(i))
              };

              let parsedCols = this.setDisabledColumns(res);
              this.saveOrder(parsedCols);

              parsedCols = parsedCols.filter(
                i =>
                  res.active.includes(i as string) ||
                  (Object.keys(this.disabledColPosition).includes(i as string) &&
                    !this.hiddenCols.includes(i as string))
              );
              this.saveActive(parsedCols);

              this.erpOrderColumnsChange.emit(parsedCols);
              this.onSetMinWidth(parsedCols.length);
              this.componentRef.instance.columnsOrder = res.order as string[];
              this.componentRef.instance.activeColumns = res.active as string[];

              this.changeDetector.markForCheck();
            }
          });
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.erpOrderColumns && !changes.erpOrderColumns.firstChange) {
      this.onSetMinWidth();
    }
  }

  private onSetMinWidth(cols?: number) {
    if (this.setMinWidth) {
      // eslint-disable-next-line no-magic-numbers
      const minLength = (cols ?? this.erpOrderColumns?.length ?? 0) * 150;

      this.el.nativeElement.style['min-width'] = `${minLength}px`;
    }
  }

  private initColumnsData() {
    this.setColumnNames();
    this.loadColumns();
    this.handleDisabledColumns();
  }

  private handleFooterColumns(parsedCols?: string[]) {
    const footerColumns = this.tableRef.querySelectorAll('tfoot td');
    const disabledActions = [...this.actionColumnNames, ...this.disabledColumns];

    if (footerColumns.length && (parsedCols?.length ?? this.componentRef.instance.activeColumns.length)) {
      const colClassStart = 'mat-column-';
      const regex = /mat-column-([^\s]+)/;

      footerColumns.forEach(i => i.setAttribute('colspan', '0'));

      const emptyCol = Array.from(footerColumns).find(col => {
        const classCol = regex.exec(col.className);

        if (classCol) {
          const colName = classCol[0].replace(colClassStart, '');

          if (!disabledActions.includes(colName)) {
            return col;
          }
        }
      });

      const colspanCount =
        (parsedCols?.length ?? this.componentRef.instance.activeColumns.length) -
        footerColumns.length +
        (emptyCol ? 1 : 0);

      (emptyCol ?? footerColumns[0]).setAttribute('colspan', `${colspanCount}`);
    }
  }

  setDisabledColumns(data: ColsResponse): Key<T>[] {
    const keys = Object.keys(this.disabledColPosition);
    const parsedCols = [...data.order];

    if (keys.length) {
      keys.forEach(key => parsedCols.splice(this.disabledColPosition[key], 0, key));
    }

    return parsedCols;
  }

  ngOnDestroy() {
    this.observer?.disconnect();
  }

  observePositionChange() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList') {
          this.setPosition();
        }
      });
    });

    const config = { childList: true, subtree: true };

    this.observer.observe(document.body, config);
  }

  loadColumns() {
    const loadedOrder = this.loadOrder();
    const loadedActive = this.loadActive();
    let orderCols = [...this.erpOrderColumns];
    let prepareArray: Key<T>[] = [];

    if (loadedOrder.length) {
      loadedOrder.forEach(i => {
        if (this.erpOrderColumns.includes(i)) {
          prepareArray.push(i);
          orderCols = orderCols.filter(col => col !== i);
        }
      });
      if (orderCols.length) {
        orderCols.forEach(col => {
          const index = this.erpOrderColumns.findIndex(i => i === col);

          if (index <= prepareArray.length) {
            prepareArray.splice(index, 0, col);
          } else {
            prepareArray.push(col);
          }
        });
      }
    }
    if (prepareArray.length) {
      this.columnsOrder = prepareArray as string[];

      if (loadedActive.length) {
        prepareArray = prepareArray.filter(
          i =>
            (loadedActive.includes(i as string) || !loadedOrder.includes(i as string)) &&
            this.erpOrderColumns.includes(i)
        );
      }
      this.erpOrderColumnsChange.emit(prepareArray);
    }
    this.componentRef.instance.activeColumns = prepareArray.length ? (prepareArray as string[]) : loadedActive;
  }

  handleDisabledColumns() {
    const cols: string[] = [];
    const disabledCols: string[] = [];
    const disabledActions = [...this.actionColumnNames, ...this.disabledColumns];
    const colsOrder = this.columnsOrder ?? this.erpOrderColumns;

    colsOrder.forEach((col, index) => {
      const column = col as string;

      if (disabledActions.includes(column)) {
        this.disabledColPosition[column] = index;
        disabledCols.push(column);
      } else {
        cols.push(column);
      }
    });
    this.componentRef.instance.columnsOrder = cols;
    this.componentRef.instance.disabledColumns = disabledCols;
    this.componentRef.instance.requiredColumns = this.requiredColumns as string[];
  }

  private setPosition() {
    const top = 42;

    if (this.iconOnHeader) {
      // new approach -> start
      if (this.tableRef && this.tableRef.tHead && this.tableRef.tHead.rows.length > 0) {
        const headerRow = this.tableRef.tHead.rows[0];
        const lastHeaderCell = headerRow.cells[headerRow.cells.length - 1];

        const wrapperDiv = lastHeaderCell.querySelector('.wrapper');

        if (wrapperDiv) {
          if (!wrapperDiv.contains(this.componentRef.location.nativeElement)) {
            this.componentRef.location.nativeElement.style.position = 'absolute';
            this.componentRef.location.nativeElement.style.left = '31px';
            this.componentRef.location.nativeElement.style.top = '12px';
            wrapperDiv.appendChild(this.componentRef.location.nativeElement);
          }
        } else {
          console.error('No .wrapper div found in the last header cell');
        }
      }
      // new approach -> end

      // // old approach -> start
      // this.renderer.setStyle(this.componentRef.location.nativeElement, 'z-index', `${100}`);
      // this.renderer.setStyle(this.componentRef.location.nativeElement, 'right', `${this.tableRef.offsetLeft + 25}px`);
      // this.renderer.setStyle(this.componentRef.location.nativeElement, 'top', `${this.tableRef.offsetTop + 13}px`);
      // // old approach -> end
    } else {
      this.renderer.setStyle(this.componentRef.location.nativeElement, 'right', `${this.tableRef.offsetLeft}px`);
      this.renderer.setStyle(this.componentRef.location.nativeElement, 'top', `${this.tableRef.offsetTop - top}px`);
    }
  }

  private setColumnNames() {
    const colClassStart = 'mat-column-';
    const regex = /mat-column-([^\s]+)/;
    const allColumns = this.tableRef.querySelectorAll('th');

    allColumns.forEach(col => {
      const classCol = regex.exec(col.className);

      if (classCol) {
        const colName = classCol[0].replace(colClassStart, '');

        if (this.erpOrderColumns.includes(colName)) {
          let name = this.getColumnText(col);

          if (!name && Object.keys(ACTION_TITLES).includes(colName)) {
            name = this.getActionColText(colName);
          }

          this.displayedColumnNames.set(colName, { name, disabled: this.actionColumnNames.includes(colName) });
        }
      }
    });
    this.componentRef.instance.columnNames = this.displayedColumnNames;
  }

  private getColumnText(col: HTMLElement) {
    return col.innerText?.trim().replace('\n', ' ').replace(/\s\s+/g, ' ');
  }

  private get activeTableKey(): string {
    return `${this.pageIdentifierService.getActiveIdentifier(this.storageIdentifier, this.tableRef)}`;
  }

  private get orderTableKey(): string {
    return `${this.pageIdentifierService.getOrderIdentifier(this.storageIdentifier, this.tableRef)}`;
  }

  private saveOrder(data: Key<T>[]) {
    localStorage.setItem(this.orderTableKey, JSON.stringify(data));
  }

  private saveActive(data: Key<T>[]) {
    localStorage.setItem(this.activeTableKey, JSON.stringify(data));
  }

  private getActionColText(key: unknown) {
    // @ts-ignore
    return ACTION_TITLES[key];
  }

  private loadOrder(): string[] {
    return JSON.parse(localStorage.getItem(this.orderTableKey) ?? '[]');
  }

  private loadActive(): string[] {
    return JSON.parse(localStorage.getItem(this.activeTableKey) ?? '[]');
  }
}
